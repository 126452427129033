<template>
  <div id="lead-form">
    <v-inner-header
      v-if="viewType == 'edit'"
      type="questionnaire"
      mode="view"
      :item="questionnaire"
      :redirect="`/projects/${questionnaire.project_id}`"
      :template="false"
      @edit="edit"
      @share="copyShareLink"
      @deleteItem="deleteItem"
      @markAsSent="markAsSent"
      @markAsUnsent="markAsUnsent"
    />

    <div
      v-if="loading"
      class="w-full px-2 text-center"
    >
      <img
        src="@/assets/img/icons/loader.svg"
        width="50"
        class="mt-32 text-center"
      >
    </div>
    <div v-else>
      <div v-if="!loadError && questionnaire">
        <form
          id="lead"
          class="py-6 px-6"
          :class="[viewType == 'edit' ? 'mt-24' : 'mt-10']"
          @submit.prevent="save"
        >
          <div
            v-if="logo.path && formSetting.showLogo"
            class="logo text-center mb-10"
          >
            <img
              :src="logo.path"
              width="100%"
              :style="[logo.width > logo.height ? {'max-width' : '278px'} : '', logo.width < logo.height ? { 'width' : 'auto', 'max-height':'250px'} : '', logo.width == logo.height ? { 'width' : '88px', 'height':'88px'} : '' ]"
            >
          </div>

          <div
            class="container mx-auto max-w-sm mb-8"
          >
            <h1 class="mb-3">
              {{ formSetting.title }}
            </h1>
            <p>
              {{ formSetting.welcomeMessage }}
            </p>
          </div>
          <div
            v-for="(field, index) in questionnaire.fields"
            :key="field.id"
            class="container mx-auto max-w-sm items-start content-start flex mb-5"
          >
            <div class="w-full">
              <label
                v-if="field.type != 'staticText'"
                class="block mb-2 "
                :class="[questionnaire.state != 'answered' ? '' : 'font-bold']"
              >
                {{ field.label }} <span
                  v-if="field.is_required && questionnaire.state != 'answered'"
                  class="float-right text-grey "
                >Required </span>
              </label>
              <div
                v-if="field.type == 'staticText'"
                v-html="field.label"
              />

              <div v-if="field.answer && questionnaire.state == 'answered'">
                <div
                  v-if="field.type != 'checkbox'"
                >
                  <span v-if="field.type != 'datepicker'">{{ field.answer.value }}</span>
                  <span v-else>{{ field.answer.value | humanDateNoConvert }}</span>
                </div>
                <div v-if="field.type == 'checkbox'">
                  <div
                    v-for="(option, index) in field.checkboxOption"
                    class="mb-2"
                  >
                    <div
                      class="custom-checkbox inline-block"
                      style="width: auto!important"
                    >
                      <input
                        :id="field.id + '_' + index + '_' + option.value"
                        v-model="field.answer.value[index].checked"
                        :name="field.name"
                        class="styled-checkbox"
                        type="checkbox"
                        disabled
                      >
                      <label
                        :id="field.name"
                        :for="field.id + '_' + index + '_' + option.value"
                      >
                        {{ option.value }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="questionnaire.state != 'answered'">
                <input
                  v-if="field.type == 'text' && field.mapping == 'email'"
                  v-model.lazy="field.value"
                  v-validate="{ required: field.is_required, email: true }"
                  class="w-full lead"
                  type="text"
                  :name="field.name"
                >
                <input
                  v-if="field.type == 'text' && field.mapping != 'email'"
                  :value="getFieldValue(field)"
                  @input="setFieldValue(field, $event.target.value)"
                  v-validate="{ required: field.is_required }"
                  class="w-full lead"
                  type="text"
                  :name="field.name"
                >
                <textarea
                  v-if="field.type == 'textarea'"
                  :value="getFieldValue(field)"
                  @input="setFieldValue(field, $event.target.value)"
                  v-validate="{ required: field.is_required }"
                  :name="field.name"
                ></textarea>
                <v-date-picker
                  v-if="field.type == 'datepicker'"
                  :value="getFieldValue(field)"
                  v-validate="{ required: field.is_required }"
                  class="datepicker"
                  :name="field.name"
                  :popover="{ visibility: 'click' }"
                  :masks="{L: 'WWW, MMM D, YYYY'}"
                  @input="setFieldValue(field, $event)"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </v-date-picker>
                <div v-if="field.type == 'checkbox'">
                  <div v-for="(option, index) in field.checkboxOption" class="mb-2">
                    <div class="custom-checkbox inline-block" style="width: auto!important">
                      <input
                        :id="field.id + '_' + index + '_' + option.value"
                        :checked="getFieldValue(field, index)"
                        @change="setFieldValue(field, $event.target.checked, index)"
                        v-validate="{ required: field.is_required }"
                        :name="field.name"
                        class="styled-checkbox"
                        type="checkbox"
                      >
                      <label :id="field.name" :for="field.id + '_' + index + '_' + option.value">
                        {{ option.value }}
                      </label>
                    </div>
                  </div>
                </div>
                <div v-if="field.type == 'dropdown'">
                  <div
                    class="select-container tall w-full"
                  >
                    <select
                      v-model="field.value"
                    >
                      <option
                        v-for="option in field.options"
                        :value="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  v-if="errors.first(`${field.name}`)"
                  class="error mt-1"
                >
                  {{ errors.first(`${field.name}`) | formatLeadFormError }}.
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="viewType == 'token' && questionnaire.state == 'answered'"
            class="w-full max-w-md mx-auto my-2 text-center"
          >
            <input
              type="button"
              name="edit"
              value="Edit"
              @click="editAnswers"
              class="green-btn"
            >
          </div>
          <div
            v-show="viewType == 'token' && questionnaire.state != 'answered'"
            class="w-full max-w-md mx-auto my-2 text-center"
          >
            <div v-show="!saving">
              <input
                type="submit"
                name="submit"
                value="Submit"
                class="green-btn"
              >
            </div>
            <div v-show="saving">
              <img
                src="@/assets/img/icons/loader.svg"
                width="42"
                class="text-center"
              >
            </div>
          </div>
          <div
            v-if="viewType == 'token' && formSetting.successMessage != '' && (success || questionnaire.state == 'answered')"
            class="success mt-5 text-center"
          >
            <div
              class="bg-blue-washed-light mx-auto px-3 py-3 inline-block rounded"
            >
              <pre>{{ formSetting.successMessage }}</pre>
            </div>
          </div>
        </form>
      </div>
      <div
        v-else
        class="text-center bg-blue-washed-light px-10 py-10 rounded mt-5 text-sm"
      >
        There is an error loading the questionnaire, <br> please double check your link. <br><br>
        <router-link to="/">
          <img
            src="/images/logo.png"
            width="88"
          >
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import { addDays, format } from 'date-fns'
import { parseFromTimeZone } from 'date-fns-timezone'

export default {
  data(){
    return {
      loading:false,
      loadError: false,
      saving: false,
      viewType: 'token',
      questionnaire: {},
      company_id: null,
      success: false,
      logo: {path:'', width:0, height:0 },
      id: this.$route.params.questionnaireId,
      token: this.$route.params.token,
      formSetting: {
        token: '',
        name: '',
        title: '',
        showLogo: true,
        welcomeMessage: '',
        successMessage: '',
        isRedirectOn: false,
        redirectLink: '',
      },
    }
  },
  async mounted(){
    this.getData()
  },
  methods: {
    getFieldValue(field, index) {
      if (field.type === 'checkbox' && field.answer && field.answer.value && field.answer.value[index]) {
        return field.answer.value[index].checked;
      }
      return field.answer && field.answer.value ? field.answer.value : '';
    },
    setFieldValue(field, value, index) {
      if (field.type === 'checkbox') {
        if (!field.checkboxOption) {
          this.$set(field, 'checkboxOption', []);
        }
        // Get the current option at the index
        let currentOption = field.checkboxOption[index];
        if (!currentOption) {
          // If the current option doesn't exist, then initialize with default values
          currentOption = { value: '', checked: false };
        }
        // Update the checked property of currentOption
        currentOption.checked = value;
        // Set it back to the checkboxOption array
        this.$set(field.checkboxOption, index, currentOption);
      } else {
        if (!field.answer) {
          this.$set(field, 'answer', { value: '' });
        }
        field.answer.value = value;
      }
    },

    async getData(){
      this.loading = true
      let target = this.token
      let that = this

      try {
        let result = {}
        if(!this.token) {
          target = this.id
          this.viewType = 'edit'
          result  = await this.$api.get('questionnaire').detail(target, `${this.viewType}`)
        } else {
          this.viewType = 'token'
          result  = await this.$api.get('questionnaire').view(target, `${this.viewType}`)
        }

        let data = result.data
        this.questionnaire = data.questionnaire
        this.formId = data.questionnaire.id
        this.formState = data.questionnaire.state
        this.formSetting.token = data.questionnaire.token
        this.formSetting.name = data.questionnaire.name
        this.formSetting.title = data.questionnaire.title
        this.formSetting.welcomeMessage = data.questionnaire.welcome_message
        this.formSetting.successMessage = data.questionnaire.success_message
        this.formSetting.showLogo = data.questionnaire.show_logo
        this.formSetting.isRedirectOn = data.questionnaire.is_redirect_on
        this.formSetting.redirectLink = data.questionnaire.redirect_link
        this.companyId = data.questionnaire.company_id

        data.questionnaire.fields.forEach(field => {
          let options = []
          if(field.options){
            options = field.options
          }
          if(field.type == 'dropdown'){
            field.value = field.options[0]
          }
          if(field.type == 'datepicker'){
            if(field.answer){
              field.answer.value = (field.answer.value !== null) ? new Date(field.answer.value) : null
            }
          }
          if(field.type == 'checkbox'){
            field.checkboxOption = []
            field.options.forEach(option => {
              let obj = { 'value': option, 'checked' : false }
              field.checkboxOption.push(obj)
            })
            if(field.answer){
              field.answer.value = JSON.parse(field.answer.value)
              field.checkboxOption.forEach((option, index) => {
                if(field.answer.value[index]){
                  option.checked = field.answer.value[index].checked
                }
              })
            }
          }
        })

        this.logo.path = data.logo
        if(this.logo.path){
          let image = new Image()
          image.src = this.logo.path
          image.onload = function () {
            that.logo.width = this.width
            that.logo.height = this.height
          }
        }

        this.loading = false

      } catch(e){
        this.$toasted.global.api_error(e)
      }
    },
    save() {
      if(!this.saving){
        this.$validator.validate().then(result => {
          if(result){
            this.doSave()
          }
        })
      }
    },
    editAnswers() {
      // change questionnaire.state to sent
      this.questionnaire.state = 'sent'
    },
    async doSave(saveAndEditLater = false) {

      let that = this
      this.saving = true

      if(this.viewType == 'edit'){
        if(this.formSetting.isRedirectOn){
          window.location.replace(this.formSetting.redirectLink)
        } else {
          this.success = true
          this.$nextTick(() => {
              this.errors.clear()
              this.$nextTick(() => {
                  this.$validator.reset()
              })
          })
        }

        this.questionnaire.state = 'answered'
        this.saving = false
        return false
      }

      Object.keys(this.questionnaire.fields).forEach(function (item) {
        if(that.questionnaire.fields[item].type == 'datepicker'){
          that.questionnaire.fields[item].value = (that.questionnaire.fields[item].value) ? parseFromTimeZone(that.questionnaire.fields[item].value, { timeZone: 'Etc/UTC' }) : null
        }
      })

      try {
        const postData = {
          form: this.questionnaire,
          formId: this.formId,
          companyId: this.companyId
        }

        const { data } = await this.$api.get('questionnaire').submit(this.formId, postData)

        this.getData()
        this.questionnaire.state = 'answered'
        this.saving = false

        if(this.formSetting.isRedirectOn){
          window.location.replace(this.formSetting.redirectLink)
        } else {
          this.success = true
          this.$nextTick(() => {
              this.errors.clear()
              this.$nextTick(() => {
                  this.$validator.reset()
              })
          })
        }

      } catch(e){
        this.saving = false
        this.$toasted.global.api_error(e)
      }
    },
    async copyShareLink(){
      if(this.formState == 'draft'){
        const can = await this.$alert.confirm({
          title: 'Questionnaire has not been sent out',
          text: 'In order to copy a share link, we will mark this questionnaire as sent.'
        })

        if (can) {
          try {
            await this.$api.get('questionnaire').updateState(this.formId, 'sent')
              const link = `${config.frontBaseUrl}/view/${this.questionnaire.project.id}/questionnaire/${this.questionnaire.token}`
              let dummy = document.createElement('textarea')
              document.body.appendChild(dummy)
              dummy.value = link
              dummy.select()
              document.execCommand('copy')
              document.body.removeChild(dummy)

              this.$toasted.global.general_success({
                message : 'Share link has been copied to your clipboard.'
              })

              setTimeout(() => {
                window.location.reload()
              }, 1000)

          } catch(e){
            this.$toasted.global.api_error(e)
            logException(e)
          }
        }
      } else {
        const link = `${config.frontBaseUrl}/view/${this.questionnaire.project.id}/questionnaire/${this.questionnaire.token}`
        let dummy = document.createElement('textarea')
        document.body.appendChild(dummy)
        dummy.value = link
        dummy.select()
        document.execCommand('copy')
        document.body.removeChild(dummy)

        this.$toasted.global.general_success({
          message : 'Share link has been copied to your clipboard.'
        })
      }
    },
    async deleteItem(){
      const canDelete = await this.$alert.confirm({
        title: 'Are you sure you want to delete this questionnaire?',
        text: 'If you delete this, sent item will no longer accessible by the client.',
      })

      if (canDelete) {
        try {
          try {
            const postData = {
              formId: this.formId,
            }
            await this.$api.get('questionnaire').delete(postData)
            this.$router.push(`/projects/${this.$route.params.id}`)

          } catch(e){
            this.$toasted.global.api_error(e)
            logException(e)
          }

        } catch(e){
          logException(e)
        }
      }
    },
    async markAsSent(){

      const can = await this.$alert.confirm({
        title: 'Are you sure you want to mark this questionnaire as sent?',
      })

      if (can) {
        try {
          await this.$api.get('questionnaire').updateState(this.formId, 'sent')
          setTimeout(() => {
            window.location.reload()
          }, 500)

        } catch(e){
          this.$toasted.global.api_error(e)
        }
      }
    },
    async markAsUnsent(){

      const can = await this.$alert.confirm({
        title: 'Are you sure you want to mark this questionnaire as draft?',
      })

      if (can) {
        try {
          await this.$api.get('questionnaire').updateState(this.formId, 'draft')
          setTimeout(() => {
            window.location.reload()
          }, 500)

        } catch(e){
          this.$toasted.global.api_error(e)
        }
      }
    },
    async edit(){
      this.$router.push(`/projects/${this.questionnaire.project.id}/questionnaire/${this.questionnaire.id}`)
    },
  },
}
</script>
